import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import FormLayout from '../layouts/form';

import ContactFormCatalog from '../components/contact-form-catalog/index';

const CatalogOrderPage = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      metaRobots,
      metaCanonical,
      header: headers,
    },
    datoCmsConfig: {
      pagePrivacy,
    },
    allDatoCmsCatalog: {
      nodes: catalogs,
    },
  },
  pageContext: { lang: locale },
  location,
}) => (
  <FormLayout
    seoTags={seoMetaTags}
    metaRobots={metaRobots}
    metaCanonical={metaCanonical}
    headline={headers?.length > 0 ? headers[0].text ?? headers[0].headline : undefined}
  >
    <ContactFormCatalog
      locale={locale}
      privacyLink={pagePrivacy.fullUrlPath}
      catalogs={catalogs}
      checkedCatalog={location.state?.catalog}
    />
  </FormLayout>
);

export default CatalogOrderPage;

CatalogOrderPage.defaultProps = {
  location: {},
};

CatalogOrderPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};

export const query = graphql`
query catalogFormularQuery($url: String, $lang: String) {
    datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        metaCanonical
        metaRobots
    }

    datoCmsConfig(locale: {eq: $lang}) {
        pagePrivacy {
            fullUrlPath
        }
    }

    allDatoCmsCatalog(
        filter: {locale: {eq: $lang}, orderable: {eq: true}}
        sort: {fields: date, order: DESC}
    ) {
        nodes {
            id
            name
        }
    }
}`;
