import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import t from '../../lib/translate/index';
import { useFormSubmit } from '../../lib/service/formSubmit/useFormSubmit';

const ContactFormCatalog = ({
  locale,
  privacyLink,
  catalogs,
  checkedCatalog,
}) => {
  const privacyFieldLbl = t(
    'form_catalogorder_privacy_label',
    `<a href="${privacyLink}" target="_blank" rel="noreferrer">${t('form_catalogorder_privacy_link_label')}</a>`,
  );

  const Checkbox = (checkProps) => (
    // eslint-disable-next-line react/destructuring-assignment
    <Field name={checkProps.name}>
      {({ field, form }) => (
        <input
          type="checkbox"
          {...checkProps}
          checked={field.value.includes(checkProps.value)}
          onChange={() => {
            if (field.value.includes(checkProps.value)) {
              const nextValue = field.value.filter((value) => value !== checkProps.value);
              form.setFieldValue(checkProps.name, nextValue);
            } else {
              const nextValue = field.value.concat(checkProps.value);
              form.setFieldValue(checkProps.name, nextValue);
            }
          }}
        />
      )}
    </Field>
  );

  const formSubmit = useFormSubmit(locale, 'catalogs');

  const onSubmit = async ({ privacy, items, ...data }, { setSubmitting }) => {
    setSubmitting(true);

    const validationErrors = await formSubmit.mutateAsync({
      ...data,
      itemNames: items.join(', '),
      privacy: privacy === true ? 1 : -1,
    });

    setSubmitting(false);

    if (!validationErrors) {
      // no validation errors, we're done here
      return;
    }

    // validation errors

    const validationMsg = Object.keys(validationErrors)
      .reduce((carry, key) => {
        carry.push(`${key}: ${validationErrors[key].join(' | ')}`);
        return carry;
      }, []).join('\n');

    // eslint-disable-next-line no-alert
    alert(validationMsg);
  };

  return (
    <>
      {formSubmit.isSuccess && (
        <>
          <p>{t`form_catalogorder_submit_success_txt`}</p>
          <footer className="form-footer">
            <div className="inner">
              <button type="submit" disabled className="btn btn-primary">{t`form_catalogorder_submit_button_label`}</button>
              <button type="button" className="btn btn-outline" onClick={() => navigate(-1)}>
                {t`form_catalogorder_back_button_label`}
              </button>
            </div>
          </footer>
        </>
      )}

      {formSubmit.isLoading && (
        <>
          <span>LOADING...</span>
          <footer className="form-footer">
            <div className="inner">
              <button type="submit" disabled className="btn btn-primary">{t`form_catalogorder_submit_button_label`}</button>
              <button type="button" disabled className="btn btn-outline">{t`form_catalogorder_back_button_label`}</button>
            </div>
          </footer>
        </>
      )}

      {!formSubmit.isLoading && !formSubmit.isSuccess && (
        <Formik
          initialValues={{
            items: checkedCatalog ? [`${checkedCatalog}`] : [],
            firstname: '',
            lastname: '',
            email: '',
            street: '',
            zip: '',
            city: '',
            privacy: false,
          }}
          validationSchema={Yup.object({
            items: Yup.array().min(1, t`validation_err_required`).required(t`validation_err_required`),
            firstname: Yup.string().required(t`validation_err_required`),
            lastname: Yup.string().required(t`validation_err_required`),
            email: Yup.string().email(t`validation_err_email`).required(t`validation_err_required`),
            street: Yup.string().required(t`validation_err_required`),
            zip: Yup.string().required(t`validation_err_required`),
            city: Yup.string().required(t`validation_err_required`),
            privacy: Yup.boolean().oneOf([true], t`validation_err_required`).required(t`validation_err_required`),
          })}
          onSubmit={onSubmit}
        >
          <Form className="needs-validation">
            <p className="required-info">{t`form_required_fields`}</p>
            <fieldset>
              {catalogs.map(({ id, name }) => (
                <div key={id} className="form-check">
                  <Checkbox className="form-check-input" name="items" value={name} id={id} />
                  <label className="form-check-label" htmlFor={id}>{name}</label>
                </div>
              ))}

              <ErrorMessage name="items">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </fieldset>
            <div className="form-group">
              <label htmlFor="firstname">
                {t`form_catalogorder_surname_label`}
                <span className="required_asterisk">{t`form_required_asterisk`}</span>
              </label>
              <Field className="form-control" type="text" name="firstname" id="firstname" placeholder={t`form_catalogorder_surname_placeholder`} />
              <ErrorMessage name="firstname">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="lastname">
                {t`form_catalogorder_lastname_label`}
                <span className="required_asterisk">{t`form_required_asterisk`}</span>
              </label>
              <Field className="form-control" type="text" name="lastname" id="lastname" placeholder={t`form_catalogorder_lastname_placeholder`} />
              <ErrorMessage name="lastname" className="invalid-feedback">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="email">
                {t`form_catalogorder_email_label`}
                <span className="required_asterisk">{t`form_required_asterisk`}</span>
              </label>
              <Field className="form-control" type="email" name="email" id="email" placeholder={t`form_catalogorder_email_placeholder`} />
              <ErrorMessage name="email">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="street">
                {t`form_catalogorder_address_label`}
                <span className="required_asterisk">{t`form_required_asterisk`}</span>
              </label>
              <Field className="form-control" type="text" name="street" id="street" placeholder={t`form_catalogorder_address_placeholder`} />
              <ErrorMessage name="street">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="zip">
                {t`form_catalogorder_zip_label`}
                <span className="required_asterisk">{t`form_required_asterisk`}</span>
              </label>
              <Field className="form-control" type="text" name="zip" id="zip" placeholder={t`form_catalogorder_zip_placeholder`} />
              <ErrorMessage name="zip">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <label htmlFor="city">
                {t`form_catalogorder_city_label`}
                <span className="required_asterisk">{t`form_required_asterisk`}</span>
              </label>
              <Field className="form-control" type="text" name="city" id="city" placeholder={t`form_catalogorder_city_placeholder`} />
              <ErrorMessage name="city">
                { (msg) => <p>{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="form-group">
              <div className="form-check">
                <Field className="form-check-input" name="privacy" type="checkbox" id="privacy" />
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line react/no-danger,jsx-a11y/label-has-associated-control */}
                <label className="form-check-label" htmlFor="privacy" dangerouslySetInnerHTML={{ __html: privacyFieldLbl }} />
                <ErrorMessage name="privacy" id="privacy">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>
            </div>
            <footer className="form-footer">
              <div className="inner">
                <button type="submit" className="btn btn-primary">{t`form_catalogorder_submit_button_label`}</button>
                <button type="button" className="btn btn-outline" onClick={() => navigate(-1)}>
                  {t`form_catalogorder_back_button_label`}
                </button>
              </div>
            </footer>
          </Form>
        </Formik>
      )}
    </>
  );
};
ContactFormCatalog.defaultProps = {
  checkedCatalog: '',
};
ContactFormCatalog.propTypes = {
  locale: PropTypes.string.isRequired,
  privacyLink: PropTypes.string.isRequired,
  catalogs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  checkedCatalog: PropTypes.string,
};
export default ContactFormCatalog;
